import React, { useContext } from "react";
import { useState, useEffect } from "react";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { Container, Row, Col, Form, InputGroup } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import Offcanvas from "react-bootstrap/Offcanvas";
import "./ViewOffCanvance.css";
import { Context } from "../../../utils/context";
import { useForm, Controller } from "react-hook-form";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";
import "react-phone-input-2/lib/bootstrap.css";
import Select from "react-select";
import { CancelButton, SaveButton } from "../../common/Button";
import ModalSave from "../../common/ModelSave";

library.add(fas);

const ViewOffCanvance = ({ handleClose, setShow, show, id, ...props }) => {
  const [value, setValue] = React.useState("1");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const { postData, getData, IMG_URL } = useContext(Context);
  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    getValues,
  } = useForm();

  // const onSubmit = async (data) => {
  //   try {
  //     const dataToSend = new FormData();
  //     dataToSend.append("stage", data?.stage?.value);
  //     dataToSend.append("email", getValues("email"));
  //     const response = await postData(
  //       `/employee/seller/request/${props?.id}`,
  //       dataToSend
  //     );
  //     console.log(response);
  //     if (response?.success) {
  //       await setShowModal({ code: response.code, message: response.message });
  //     } else {
  //       await setShowModal({ code: response.code, message: response.message });
  //     }
  //     setTimeout(() => {
  //       setShowModal(0);
  //       props.handleClose();
  //     }, 1000);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };


  const [data, setData] = useState();
  const getSellerData = async () => {
    const res = await getData(
      `/order/${id}`
    );
    setData(res?.data);
    reset(res?.data);
  };

  useEffect(() => {
    getSellerData();
  }, [id]);



  return (
    <>
      <Offcanvas
        show={show}
        style={{ width: "80%", height: "auto" }}
        placement={"end"}
        onHide={handleClose}
        className="offcan"
      >
        <Container className="tabss-main">
          <Offcanvas.Header closeButton></Offcanvas.Header>
          <Offcanvas.Body>
            <Card className="shadow-lg p-3 mb-5 bg-white rounded">
              <Card.Body>
                <Card.Title>
                  <h3>Seller Details </h3>
                </Card.Title>

                <Box sx={{ width: "100%", typography: "body1" }}>
                  <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                      <TabList
                        onChange={handleChange}
                        aria-label="lab API tabs example"
                      >
                        <Tab label="Order Details" value="1" />
                        <Tab label="order Address" value="2" />

                        <Tab label="Gift Details" value="3" />


                        <Tab label="Customize Details" value="4" />

                        {/* <Tab label="Bank Details" value="5" /> */}
                      </TabList>
                    </Box>
                    <TabPanel value="1">
                      <div>
                        <div>
                          <h3>Order Details</h3>
                        </div>

                        <div>
                          <Row>
                            <Col lg={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>Order Invoice Number</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={data?.invoice_number}
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>

                            <Col lg={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>Selling Price</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={data?.price}
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>

                            <Col lg={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>MRP</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={data?.mrp}
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>

                            <Col lg={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>Total Tax</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={data?.total_tax}
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>

                            <Col lg={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>Gift Wrap Price</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={data?.gift_wrap_price || 0}
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>

                            <Col lg={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>Customize Price</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={data?.customize_price}
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>

                            <Col lg={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>Total Price</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={data?.total_amount}
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </TabPanel>
                    <TabPanel value="2">
                      <div>
                        <div>
                          <h3>Order Address</h3>
                        </div>
                        <div>
                          <Row>
                            <Col md={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>Address</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={
                                          data?.order?.shipping_address?.address
                                        }
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>
                            <Col md={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>City</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={
                                          data?.order?.shipping_address?.city?.name
                                        }
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>
                            <Col md={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>State</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={
                                          data?.shipping_address?.state?.name
                                        }
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>
                            <Col md={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>Country</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={
                                          data?.order?.shipping_address?.country?.name
                                        }
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>
                            <Col md={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>Pincode</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={
                                          data?.order?.shipping_address?.pincode?.name
                                        }
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </TabPanel>

                    <TabPanel value="3">
                      <div>
                        <div>
                          <h3>Gift Details</h3>
                        </div>

                        <div>
                          <Row>
                            <Col md={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>From</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={
                                          data?.gift_detail?.from
                                        }
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>
                            <Col md={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>To</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={
                                          data?.gift_detail?.to
                                        }
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>
                            <Col md={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>Message</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={
                                          data?.gift_detail?.message
                                        }
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>
                            <Col md={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>Gift Wrap Price</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={
                                          data?.gift_detail?.gift_wrap_price
                                        }
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </TabPanel>

                    <TabPanel value="4">
                      <div>
                        <div>
                          <h3>Customize Details</h3>
                        </div>

                        <div>
                          <Row>
                            <Col md={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>Customer Name</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={
                                          data?.customize?.name
                                        }
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>

                            <Col md={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>Customer Email</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={
                                          data?.customize?.email
                                        }
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>
                            <Col md={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label> Customer contact</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={data?.customize?.contact_no}
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>
                            <Col md={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>Company Name</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={data?.customize?.company_name}
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>
                            <Col md={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>Requirement</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={data?.customize?.requirement}
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>

                            <Col md={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>Requirement</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={data?.customize?.requirement}
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>
                            <Col md={12}>
                            <Row >
                            <Form.Label>Customise Images</Form.Label>
                            </Row>
                            {data?.customize?.customize_images?.map((val) => (
                              <img
                                src={`${IMG_URL}${val?.image}`}
                                alt="Image"
                                width="50px"
                                height="50px"
                                style={{ marginRight: "10px", margin: "10px" }}
                              />
                            ))}
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </TabPanel>
                  </TabContext>
                </Box>
              </Card.Body>
            </Card>
          </Offcanvas.Body>


          <Row className="mt-5 pb-3">
            <div className="d-flex justify-content-center">
              <CancelButton name={"Back"} handleClose={handleClose} />

              {/* <SaveButton name={"save"} handleSubmit={handleSubmit(onSubmit)} /> */}
            </div>
          </Row>
        </Container>
      </Offcanvas>
      <ModalSave
        message={showModal?.message}
        showErrorModal={showModal?.code ? true : false}
      />
    </>
  );
};

export default ViewOffCanvance;
