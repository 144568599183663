import React, { useContext, useRef } from "react";
import { useState, useEffect } from "react";
import "../../Tabels/Tabels.css";
import { Link } from "react-router-dom";
import { Context } from "../../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
// *******************toster****************************
import ModalSave from "../../common/ModelSave";
import { CancelButton, SaveButton } from "../../common/Button";
import { Row, Col, Form, InputGroup, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import classNames from "classnames";
library.add(fas);

const EditOffCanvance = (props) => {
  const id = props.show;
  const videoRef = useRef(null);
  const { postData, getData, IMG_URL } = useContext(Context);
  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });
  const [videoPreview, setVideoPreview] = useState(null);
  const GetEditData = async () => {
    const response = await getData(`/masters/website-type/${id}`);
    reset(response?.data);
    setVideoPreview(IMG_URL + response?.data?.video);
  };
  useEffect(() => {
    GetEditData();
  }, []);
  console.log(videoPreview, "videoPreview");

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    reset,
    getValues,
    watch,
    setValue,
  } = useForm();

  const videoFile = watch("video");

  const onSubmit = async (data) => {
    try {
      console.log(data);
      const finalData = new FormData();
      finalData.append("name", data?.name);
      finalData.append("sub_name", data?.sub_name);
      finalData.append("video", data.video[0]);
      const response = await postData(`/masters/website-type/${id}`, finalData);
      console.log(response);
      if (response?.success) {
        props.getDataAll();
        await setShowModal({ code: response.code, message: response.message });
      } else {
        await setShowModal({ code: response.code, message: response.message });
      }
      setTimeout(() => {
        setShowModal(0);
        props.handleClose();
      }, 1000);
    } catch (error) {
      console.log(error);
    }
  };

  const handleVideoChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type.startsWith("video")) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setVideoPreview(reader.result); // Set video preview
      };
      reader.readAsDataURL(file);
      // setValue('video', file); // Update the form value
    } else {
      // Optionally, handle the case when the selected file is not a video
      alert("Please select a video file.");
    }
  };
  return (
    <>
      <Modal
        {...props}
        onHide={props.handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Edit Website Type
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={() => handleSubmit(onSubmit)} role="form">
            <Row className="justify-content-center">
              <Col md={6}>
                <div className="main-form-section my-3">
                  <Row className="justify-content-center">
                    <Form.Group>
                      <Form.Label>Name</Form.Label>

                      <InputGroup>
                        <Form.Control
                          type="text"
                          name="name"
                          placeholder="Name"
                          className={classNames("", {
                            "is-invalid": errors?.name,
                          })}
                          {...register("name", {
                            required: "Name Is Required",
                          })}
                        />
                      </InputGroup>
                      {errors.name && (
                        <span className="text-danger">
                          {errors.name.message}
                        </span>
                      )}
                    </Form.Group>
                  </Row>
                </div>
                <div className="main-form-section my-3">
                  <Row className="justify-content-center">
                    <Form.Group>
                      <Form.Label>Sub Name</Form.Label>

                      <InputGroup>
                        <Form.Control
                          type="text"
                          name="name"
                          placeholder="Sun Tital"
                          className={classNames("", {
                            "is-invalid": errors?.sub_name,
                          })}
                          {...register("sub_name", {
                            required: "sub name Is Required",
                          })}
                        />
                      </InputGroup>
                      {errors.sub_name && (
                        <span className="text-danger">
                          {errors.sub_name.message}
                        </span>
                      )}
                    </Form.Group>
                  </Row>
                </div>

                {/* <div className="main-form-section my-3">
                  <Row className="justify-content-start">
                    <Form.Label className="text-left">Video</Form.Label>

                    <Form.Group>
                      <Form.Control
                        className={classNames("", {
                          "is-invalid": errors?.video,
                        })}
                        type="file"
                        {...register("video", {

                          

                        })}
                        // onChange={handleVideoChange}
                        accept=".mp4, .webm, .mpeg"
                      />
                    </Form.Group>
                    {errors.video && (
                      <span className="text-danger">
                        {errors.video.message}
                      </span>
                    )}
                  </Row>
                </div> */}

                <div className="main-form-section my-3">
                  <Row className="justify-content-center">
                    <Form.Label column sm={3} className="text-center">
                      Video
                    </Form.Label>
                    <Col sm={9}>
                      <Form.Group>
                        <Form.Control
                          className={classNames("", {
                            "is-invalid": errors?.video,
                          })}
                          type="file"
                          {...register("video", {
                            // required: "image1 is required",
                            // validate: async (value) => {
                            //   const fileTypes = ["jpg", "png", "jpeg"];
                            //   const fileType = value[0].name.split(".")[1];
                            //   if (!fileTypes.includes(fileType)) {
                            //     return `please upload a valid file format. (${fileTypes})`;
                            //   }
                            //   const sizes = await getDimension(value[0]);
                            //   if (sizes.width !== 285 && sizes.height !== 298) {
                            //     return "Image width and height must be 285px and 298px";
                            //   }
                            //   const fileSize = Math.round(value[0].size / 1024);
                            //   if (fileSize > 500) {
                            //     return "file size must be lower than 500kb";
                            //   }
                            // },
                          })}
                          accept="video/mp4, video/x-m4v, video/*"
                        />
                      </Form.Group>
                      {errors.video && (
                        <span className="text-danger">
                          {errors.video.message}
                        </span>
                      )}
                    </Col>
                  </Row>
                </div>
              </Col>

              <Col lg={6}>
                <div className="main-form-section my-3">
                  <Form.Label className="text-center">Video Preview</Form.Label>
                  <Row className="justify-content-center">
                    <Col sm={9}>
                      {typeof getValues("video") == "string" ? (
                        <div className="image-preview-container">
                          <video
                            src={IMG_URL + getValues("video")}
                            alt="Preview"
                            className="image-preview"
                            style={{ width: "150px", height: "130px" }}
                            muted
                            controls
                          />
                        </div>
                      ) : (
                        videoFile &&
                        videoFile?.length > 0 && (
                          <div className="image-preview-container">
                            <video
                              // src={URL.createObjectURL(getValues("image")[0])}
                              src={URL?.createObjectURL(videoFile[0])}
                              alt="Preview"
                              className="image-preview"
                              style={{ width: "150px", height: "130px" }}
                              controls
                              muted
                            />
                          </div>
                        )
                      )}
                    </Col>
                  </Row>
                </div>
              </Col>

              {/* <Col lg={6}>
                <div className="main-form-section my-3">
                  <Form.Label>Video Preview</Form.Label>
                  {videoPreview && (
                    <div className="mt-2">
                      <video
                        ref={videoRef}
                        controls
                        className="video-preview"
                        style={{ width: '100%', height: 'auto' }}
                      >
                        <source src={videoPreview} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    </div>
                  )}

                 {typeof getValues("video") == "string" ? (
                    <div className="video-preview-container">
                      <video
                        ref={videoRef}
                        controls
                        className="video-preview"
                        style={{ width: "100%", height: "auto" }}
                      >
                        <source src={IMG_URL + getValues("video")} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    </div>
                  ) : (
                    videoFile && videoFile.length > 0 && (
                      <div className="video-preview-container">
                        <video
                          ref={videoRef}
                          controls
                          className="video-preview"
                          style={{ width: "100%", height: "auto" }}
                          onLoadedMetadata={handleVideoChange}
                        >
                          <source src={URL.createObjectURL(videoFile[0])} type={videoFile[0].type} />
                          Your browser does not support the video tag.
                        </video>
                      </div>
                    )
                  )} 
                </div>
              </Col> */}

              <Row className="mt-5 pb-3">
                <div className="d-flex justify-content-center">
                  <Link>
                    <CancelButton
                      name={"cancel"}
                      handleClose={props.handleClose}
                    />
                  </Link>

                  <SaveButton
                    name={"save"}
                    handleSubmit={handleSubmit(onSubmit)}
                  />
                </div>
              </Row>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>

      <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
    </>
  );
};

export default EditOffCanvance;
