import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "../../Tabels/Tabels.css";
import plus from "../../../Components/assets/icons/a1.png";
import colunms from "../../../Components/assets/icons/LINES.png";
import pen from "../../../Components/assets/icons/pen.png";
import basket from "../../../Components/assets/icons/basket.png";
import search1 from "../../../Components/assets/icons/search.png";
import top from "../../../Components/assets/icons/top.png";
import Table from "react-bootstrap/Table";
import circle from "../../assets/icons/circle.png";
import rigth from "../../assets/icons/rigth.png";
import save from "../../assets/icons/save.png";
import cancel from "../../assets/icons/cross.png";
import { Link, useNavigate, useParams } from "react-router-dom";
import Header from "../../Header/Header";
import { Context } from "../../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faEye, fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
// *******************toster****************************
import toast, { Toaster } from "react-hot-toast";
import { Button } from "react-bootstrap";

library.add(fas);

const Tables = () => {
  const {
    IMG_URL,
    getData,
    editStatusData,
    deleteData,
    ErrorNotify,
    getDownloadDataExcel,
    Per_Page_Dropdown,
  } = useContext(Context);

  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setperPage] = useState(5);
  const [search, setSearch] = useState("");
  const [searchEmail, setSearchEmail] = useState("");
  const [reset, setReset] = useState();
  const [show, setShowAdd] = useState(false);
  const [show1, setShowEdit] = useState(0);
  const [changeStatus, setChangeStatus] = useState();
  const [option, setOption] = useState();
  const [searchDate, setSearchDate] = useState('');
  const [searchDateTo, setSearchDateTo] = useState('');

  const getDataAll = async () => {
    const response = await getData(
      `/form/jobs?page=${currentPage}&per_page=${perPage}&term=${search}&searchEmail=${searchEmail}&from=${searchDate || ""}&to=${searchDateTo || ""}`
    );
    await setData(response);
    setCurrentPage(response?.data?.currentPage);
    setperPage(response?.data?.per_page);
    setSearch(response?.data?.search_name);
    // setCurrentPage(response?.data?.);
    setOption(await Per_Page_Dropdown(response?.data?.totalEntries));
    const newData = response?.data?.data;
    if (newData) {
      const newIds = newData.map((d) => d?.id);
      setAllChecked(newIds);
    }
  };

  useEffect(() => {
    getDataAll();
  }, [changeStatus, perPage, reset, show, show1]);

  const ChangeStatus = async (id) => {
    const response = await editStatusData(`/jobs/${id}`);
    setChangeStatus(response);
  };

  // Modal function
  const handleClose = () => setShowAdd(false);
  const handleShow = () => setShowAdd(true);

  const handleClose1 = () => setShowEdit(0);
  const handleShow1 = (id) => {
    setShowEdit(id);
  };

  // Select All Functionality
  const [selectAllChecked, setSelectAllChecked] = useState([]);
  const [allChecked, setAllChecked] = useState([]);

  const handleSelectAll = async () => {
    await setSelectAllChecked(allChecked);
  };

  const handleChange = async (e) => {
    const { value, checked } = e.target;

    if (value === "selectAll") {
      if (checked) {
        handleSelectAll();
      } else {
        await setSelectAllChecked([]);
      }
    } else {
      if (checked) {
        await setSelectAllChecked([...selectAllChecked, Number(value)]);
      } else {
        await setSelectAllChecked(
          selectAllChecked.filter((e) => e !== Number(value))
        );
      }
    }
  };

  // Delete module
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [recordToDeleteId, setRecordToDeleteId] = useState(null);
  const [recordToDeleteName, setRecordToDeleteName] = useState(null);

  const showDeleteRecord = async (id, name) => {
    setShowDeleteModal(true);
    setRecordToDeleteId(id);
    setRecordToDeleteName(name);
  };

  const handleDeleteRecord = async () => {
    setShowDeleteModal(false);
    if (recordToDeleteId) {
      const response = await deleteData(`/contact-us/${recordToDeleteId}`);
      // notify("error", "Deleted Succefully");

      ErrorNotify(recordToDeleteName);

      setRecordToDeleteId(null);
      setRecordToDeleteName(null);
      setChangeStatus(response);
    }
  };

  const handleDeleteCancel = () => {
    setShowDeleteModal(false);
    setRecordToDeleteId(null);
    setRecordToDeleteName(null);
  };

  //  column hide and show.....
  const [visible, setVisibel] = useState({
    col0: true,
    col1: true,
    col2: true,
    col3: true,
    col4: true,
    col5: true,
    col6: true,
    col7: true,
  });

  //toggle columnns.........
  const toggleColumn = (event, columnName) => {
    event.preventDefault();
    setVisibel((prev) => ({
      ...prev,
      [columnName]: !prev[columnName],
    }));
  };

  const HandleDownload = async () => {
    if (selectAllChecked.length == 0) {
      alert("Please Select Atleast One Record");
    } else {
      try {
        await getDownloadDataExcel(
          "/contact-us/download",
          selectAllChecked,
          "Contact us list"
        );
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  const openPdfInNewTab = (data) => {
    window.open(IMG_URL + data, "_blank");
  };
  return (
    <>
      <div className="main-advancedashboard">
        <Header title={"Job List"} link={"/home/ad-banner"} />
        <section className="AdvanceDashboard">
          <div className="row MainRowsec">
            <div className="col-lg-12">
              <div className="row MainRowsec">
                <section className="Tabels tab-radio tab-radio mt-3">
                  <div className="">
                    <div className="row">
                      <div className="d-flex">
                        <div className="add me-3"></div>

                        <div className="add me-1">
                          <div className="dropdown">
                            <button
                              className="btn btn-columns dropdown-toggle"
                              type="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <img src={colunms} className="columns me-2 " alt="" />{" "}
                              Column Selection{" "}
                              <img src={top} className="top ms-1" alt="" />{" "}
                            </button>
                            <ul className="dropdown-menu">
                              {/* <li>
                            <Link
                              className="dropdown-item"
                              onClick={(event) => toggleColumn(event, "col0")}
                              href="#"
                            >
                              Check Boxs
                              {visible?.col0 ? (
                                <FontAwesomeIcon
                                  className="ms-4"
                                  icon="fa-solid fa-eye"
                                />
                              ) : (
                                <FontAwesomeIcon
                                  className="ms-4"
                                  icon="fa-solid fa-eye-slash"
                                />
                              )}
                            </Link>
                          </li> */}
                              <li>
                                <Link
                                  className="dropdown-item"
                                  onClick={(event) => toggleColumn(event, "col1")}
                                  href="#"
                                >
                                  Sr. No.
                                  {visible?.col1 ? (
                                    <FontAwesomeIcon
                                      className="ms-4"
                                      icon="fa-solid fa-eye"
                                    />
                                  ) : (
                                    <FontAwesomeIcon
                                      className="ms-4"
                                      icon="fa-solid fa-eye-slash"
                                    />
                                  )}
                                </Link>
                              </li>
                              <li>
                                <Link
                                  className="dropdown-item"
                                  onClick={(event) => toggleColumn(event, "col2")}
                                  href="#"
                                >
                                  Name
                                  {visible?.col2 ? (
                                    <FontAwesomeIcon
                                      className="ms-2"
                                      icon="fa-solid fa-eye"
                                    />
                                  ) : (
                                    <FontAwesomeIcon
                                      className="ms-2"
                                      icon="fa-solid fa-eye-slash"
                                    />
                                  )}
                                </Link>
                              </li>
                              <li>
                                <Link
                                  className="dropdown-item"
                                  onClick={(event) => toggleColumn(event, "col3")}
                                  href="#"
                                >
                                  Email
                                  {visible?.col3 ? (
                                    <FontAwesomeIcon
                                      className="ms-2"
                                      icon="fa-solid fa-eye"
                                    />
                                  ) : (
                                    <FontAwesomeIcon
                                      className="ms-2"
                                      icon="fa-solid fa-eye-slash"
                                    />
                                  )}
                                </Link>
                              </li>

                              <li>
                                <Link
                                  className="dropdown-item"
                                  onClick={(event) => toggleColumn(event, "col4")}
                                  href="#"
                                >
                                  Mobile
                                  {visible?.col4 ? (
                                    <FontAwesomeIcon
                                      className="ms-2"
                                      icon="fa-solid fa-eye"
                                    />
                                  ) : (
                                    <FontAwesomeIcon
                                      className="ms-2"
                                      icon="fa-solid fa-eye-slash"
                                    />
                                  )}
                                </Link>
                              </li>

                              <li>
                                <Link
                                  className="dropdown-item"
                                  onClick={(event) => toggleColumn(event, "col5")}
                                  href="#"
                                >
                                  City
                                  {visible?.col5 ? (
                                    <FontAwesomeIcon
                                      className="ms-2"
                                      icon="fa-solid fa-eye"
                                    />
                                  ) : (
                                    <FontAwesomeIcon
                                      className="ms-2"
                                      icon="fa-solid fa-eye-slash"
                                    />
                                  )}
                                </Link>
                              </li>

                              <li>
                                <Link
                                  className="dropdown-item"
                                  onClick={(event) => toggleColumn(event, "col6")}
                                  href="#"
                                >
                                  Remark
                                  {visible?.col6 ? (
                                    <FontAwesomeIcon
                                      className="ms-4"
                                      icon="fa-solid fa-eye"
                                    />
                                  ) : (
                                    <FontAwesomeIcon
                                      className="ms-4"
                                      icon="fa-solid fa-eye-slash"
                                    />
                                  )}
                                </Link>
                              </li>

                              {/* <li>
                            <Link
                              className="dropdown-item"
                              onClick={(event) => toggleColumn(event, "col3")}
                              href="#"
                            >
                              Status
                              {visible?.col3 ? (
                                <FontAwesomeIcon
                                  className="ms-4"
                                  icon="fa-solid fa-eye"
                                />
                              ) : (
                                <FontAwesomeIcon
                                  className="ms-4"
                                  icon="fa-solid fa-eye-slash"
                                />
                              )}
                            </Link>
                          </li> */}
                              {/* <li>
                            <Link
                              className="dropdown-item"
                              onClick={(event) => toggleColumn(event, "col4")}
                              href="#"
                            >
                              Action
                              {visible?.col4 ? (
                                <FontAwesomeIcon
                                  className="ms-4"
                                  icon="fa-solid fa-eye"
                                />
                              ) : (
                                <FontAwesomeIcon
                                  className="ms-4"
                                  icon="fa-solid fa-eye-slash"
                                />
                              )}
                            </Link>
                          </li> */}
                            </ul>
                          </div>
                        </div>

                        {/* <div className="add">
                      <Button
                        variant="success"
                        className="btn btn-view"
                        onClick={HandleDownload}
                      >
                        {" "}
                        <FontAwesomeIcon
                          icon="fa-solid fa-download"
                          className="me-2"
                        />
                        Download
                      </Button>
                    </div> */}
                      </div>
                      <div className="row mt-4">
                        <div className="col-xxl-12 col-xl-12   col-lg-12 col-md-12 col-12">
                          <div className="d-flex">
                            <div className="show me-2">
                              <p className="show mt-1">Show</p>
                            </div>
                            <div className="number me-2">
                              <select
                                className="form-select form-select-sm"
                                aria-label=".form-select-sm example"
                                onChange={(e) => setperPage(e.target.value)}
                              >
                                {option?.map((option) => (
                                  <option key={option} value={option}>
                                    {option}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div className="entries">
                              <p className="show mt-1">entries</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-xxl-12 col-xl-12  col-lg-12  col-md-12 col-12">
                            <div className="row ">
                              <div className="  col-xl-2 col-lg-3  col-md-5 col-12 mt-md-3 mt-3">
                                <div className="sowing me-2">
                                  <p className="show mt-2">{`Showing ${Math.min(
                                    (currentPage - 1) * perPage + 1
                                  )} to ${Math.min(
                                    currentPage * perPage,
                                    data?.data?.totalEntries
                                  )} of ${data?.data?.totalEntries} entries`}</p>
                                </div>
                              </div>
                              {/* <div className=" col-xl-2 col-lg-3  col-md-5 col-12 mt-md-3 mt-3">
                                <div className="num me-2">
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Name"
                                    id=""
                                    value={search}
                                    onChange={(e) => {
                                      setSearch(e.target.value);
                                    }}
                                  />
                                </div>
                              </div> */}
                              {/* <div className="col-xl-2 col-lg-3  col-md-5 col-12 mt-md-3 mt-3">
                                <div className="num me-2">
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Email"
                                    id=""
                                    value={searchEmail}
                                    onChange={(e) => {
                                      setSearchEmail(e.target.value);
                                    }}
                                  />
                                </div>
                              </div> */}

                             
                              {/* <div className="col-xl-2 col-lg-3  col-md-5 col-12 mt-md-3 mt-3">
                                <div className="num me-2">
                                 
                                  <input
                                    type="date"
                                    className="form-control"
                                    placeholder="from"
                                    value={searchDate}
                                    onChange={(e) => setSearchDate(e.target.value)}
                                  />
                                </div>
                              </div> */}

                             
                              {/* <div className=" col-xl-2 col-lg-3  col-md-5 col-12 mt-md-3 mt-3">
                                <div className="num me-2">
                                  <input
                                    type="date"
                                    className="form-control"
                                    placeholder="to"
                                    value={searchDateTo}
                                    onChange={(e) => setSearchDateTo(e.target.value)}
                                  />
                                </div>
                              </div> */}
                              {/* <div className="col-xl-2 col-lg-3  col-md-5 col-12 mt-md-3 mt-3">
                                <div className="Search me-2">
                                  <Button
                                    type="button"
                                    onClick={getDataAll}
                                    className="btn btn-search"
                                  >
                                    <img src={search1} className="search" alt="" />
                                  </Button>
                                </div>
                              </div> */}
                              {/* <div className="col-xl-2 col-lg-3  col-md-5 col-12 mt-md-3 mt-3">
                                <div className="Search-1">
                                  <button
                                    type="button"
                                    onClick={() => {
                                      setSearch("");
                                      setSearchDate("");
                                      setSearchEmail("");
                                      setReset(!reset);
                                    }}
                                    className="btn btn-reset"
                                  >
                                    Reset
                                  </button>
                                </div>
                              </div> */}
                            </div>
                        </div>
                      </div>
                    </div>
                    <div className="border-line mt-3"></div>
                    <div className="row mt-3">
                      <div className="data table-responsive">
                        <Table striped bordered hover responsive center>
                          <thead>
                            <tr className="">
                             
                              {visible.col1 && <th className="sr" >Sr. No.</th>}
                              {visible.col2 && <th className="tax-name" style={{ width: "400px" }}>Resume</th>}
                              {
                                <th
                                  className="tax-name"
                                  style={{ width: "30%" }}
                                >
                                  View
                                </th>
                              }
                            

                            </tr>
                          </thead>
                          <tbody>
                            {data?.data?.data?.map((d, index) => {
                              return (
                                <tr className="" key={index}>
                                 
                                  {visible.col1 && <td>{++index}.</td>}
                                  {visible.col2 && (
                                    <td>
                                      <div
                                        className="role-holder"
                                        onClick={() =>
                                          openPdfInNewTab(d?.resume)
                                        }
                                      >
                                        <object
                                          data={IMG_URL + d?.resume}
                                          type="application/pdf"
                                          width="100"
                                          height="100"
                                        >
                                          <p>
                                            Browser Not Supported{" "}
                                            <a
                                              href={IMG_URL + d?.resume}
                                              target="_blank"
                                              rel="noopener noreferrer"
                                            >
                                              Download PDF
                                            </a>
                                          </p>
                                        </object>
                                      </div>
                                    </td>
                                  )}
                                  {d.resume && (
                                    <td className="text-center">
                                      <FontAwesomeIcon
                                        onClick={() =>
                                          openPdfInNewTab(d.resume)
                                        }
                                        icon={faEye}
                                        className="viewEye"
                                      />
                                    </td>
                                  )}
                                  
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </section>
      </div>

      {/* <!-- Modal Delete --> */}
      <div className="upload-modal">
        <div
          className={`modal fade ${showDeleteModal ? "show" : ""}`}
          style={{ display: showDeleteModal ? "block" : "none" }}
          id="exampleModaldel"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog  modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <h1 className="add-success text-center  mt-2">
                  Are you sure ?
                </h1>
                <p>
                  Do You Really Want to Delete These Record?
                  <br /> This Process CanNot Be Undone{" "}
                </p>
                <div className="button-holder text-center mt-2">
                  <button
                    className="btn btn-yes me-2"
                    onClick={handleDeleteRecord}
                  >
                    Yes
                  </button>
                  <button className="btn btn-no" onClick={handleDeleteCancel}>
                    No
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Toaster position="top-right" />
    </>
  );
};

export default Tables;
