import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "../../Tabels/Tabels.css";
import { Link } from "react-router-dom";
import Offcanvas from "react-bootstrap/Offcanvas";
import "./Product.css";

import Card from "react-bootstrap/Card";
import Select from "react-select";
import ModalSave from "../../common/ModelSave";
import { CancelButton, SaveButton } from "../../common/Button";
import {
  Row,
  Col,
  Form,
  Modal,
  Container,
  InputGroup,
  Button,
} from "react-bootstrap";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import classNames from "classnames";
import { Context } from "../../../utils/context";
import JoditEditor from "jodit-react";
import Table from "react-bootstrap/Table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const AddOffCanvance = (props) => {
  const { postData, getData, Select2Data, getDimension, IMG_URL } = useContext(Context);

  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [childSubCategories, setChildSubCategories] = useState([]);
  const [brands, setBrands] = useState([]);
  const [taxTypes, setTaxTypes] = useState([]);
  const [taxPercentages, setPercentages] = useState([]);
  const [attributes, setAttribute] = useState([]);
  const [subAttributes, setSubAttribute] = useState([]);
  const [occasion, setOccasion] = useState([]);
  const [material, setMaterial] = useState([]);
  const [clothsize, setClothSize] = useState([]);
  const [isClothSizeEnabled, setIsClothSizeEnabled] = useState(false);
  const [isCustomizeEnabled, setIsCustomizeEnabled] = useState(false);

  const [countries, setCountries] = useState([]);
  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  const {
    control,
    register,
    handleSubmit,
    getValues,
    formState: { errors },
    watch,
    setValue,
  } = useForm();
  const imagesFile = watch("images");
  const imageFile = watch("image1");

  // const { fields, append, remove } = useFieldArray({
  //   control,
  //   name: "variants", // Name of your field array
  // });
  const { fields: variantFields, append: appendVariant, remove: removeVariant, update: updateVariant } = useFieldArray({
    control,
    name: "variants",
  });

  // Function to handle adding a new variant
  const handleAddVariant = () => {
    appendVariant({ quantity: [] }); // Add a variant with an empty quantity array
  };

  // Function to handle removing a variant
  const handleRemoveVariant = (index) => {
    removeVariant(index); // Remove a variant by index
  };

  const [variantsError, setVariantsError] = useState("");

  useEffect(() => {
    register("variants", {
      validate: (value) => {
        const isValid = value && value.length > 0;
        setVariantsError(isValid ? "" : "At least one variant is required");
        return isValid;
      },
    });
  }, [register]);

  const validateSellingPrice = (value, index) => {
    const mrpValue = parseFloat(getValues(`variants.${index}.mrp`));
    const sellingPrice = parseFloat(value);
    if (sellingPrice >= mrpValue) {
      return "Selling price must be less than MRP";
    }
    return true; // Validation passed
  };

  const onSubmit = async (data) => {
    console.log(data, "data data data");

    try {
      const DataToSend = new FormData();

      DataToSend.append("name", data?.name);
      DataToSend.append("gift_price", data?.gift_price);
      if (data?.customize_price) {
        DataToSend.append("customize_price", data?.customize_price);
      }
      DataToSend.append("short_description", data?.short_description);
      DataToSend.append("manufacturer", data?.manufacturer);
      DataToSend.append("vender", data?.vender);
      DataToSend.append("about", data?.about);
      DataToSend.append("benifits", data?.benifits);
      DataToSend.append("storage", data?.storage);
      DataToSend.append("image1", data?.image1[0]);
      DataToSend.append("category_id", data?.category_id?.value);
      DataToSend.append("sub_category_id", data?.sub_category_id?.value);
      // DataToSend.append("child_sub_category_id", data?.child_sub_category_id?.value);
      DataToSend.append("brand_id", data?.brand_id?.value);
      DataToSend.append("tax_type_id", data?.tax_type_id?.value);
      DataToSend.append("country_id", data?.country_id?.value);
      DataToSend.append("tax_percentage_id", data?.tax_percentage_id?.value);
      DataToSend.append(
        "material_id",
        JSON.stringify(data.material_id.map((option) => option.value))
      );
      DataToSend.append(
        "occasion_id",
        JSON.stringify(data.occasion_id.map((option) => option.value))
      );
      DataToSend.append("size_status", isClothSizeEnabled);
      // Append variants
      const variants = [];


      data.variants.forEach((variant, index) => {
        const variantData = {
          v_name: variant.v_name,
          unit: variant.unit,
          mrp: variant.mrp,
          sku: variant.sku,
          s_price: variant.s_price,
          cloth_size_id: variant.cloth_size_id, // Handling multi-select cloth sizes
          quantity: [], // Initialize an empty array for quantities
        };

        // Process quantity fields associated with each variant
        if (variant.quantity) {
          variant.quantity.forEach((qty, qtyIndex) => {
            variantData.quantity.push({
              unit: qty.unit,
              min: qty.min,
              max: qty.max,
            });
          });
        }

        variants.push(variantData);

        // Handle image files associated with each variant
        const inputImages = document.getElementById(`variantImages${index}`);
        if (inputImages && inputImages.files) {
          const files = inputImages.files;
          for (let i = 0; i < files.length; i++) {
            DataToSend.append(`images${index}`, files[i]);
          }
        }
      });

      // Ensure that 'variants' and 'DataToSend' are processed correctly afterward.

      DataToSend.append("variants", JSON.stringify(variants));
      // DataToSend.append("quantity", JSON.stringify(quantity));
      const response = await postData(`/product`, DataToSend);
      console.log("response", response);
      console.log("data to send", DataToSend);
      if (response?.success) {
        await setShowModal({ code: response.code, message: response.message });
      } else {
        await setShowModal({ code: response.code, message: response.message });
      }
      setTimeout(() => {
        setShowModal(0);
        props.handleClose();
      }, 1000);
    } catch (error) {
      console.log(error);
    }
  };

  const GetAllCategory = async () => {
    const response = await getData("/allcategories");

    if (response?.success) {
      setCategories(await Select2Data(response?.data, "category_id"));
    }
  };

  const GetAllSubCategory = async (id) => {
    const response = await getData(`/allsubcategories/${id}`);

    if (response?.success) {
      setSubCategories(await Select2Data(response?.data, "sub_category_id"));
    }
  };

  const GetAllChildSubCategory = async (id) => {
    const response = await getData(`/allchildsubcategories/${id}`);

    if (response?.success) {
      setChildSubCategories(
        await Select2Data(response?.data, "sub_category_id")
      );
    }
  };

  const GetAllBrands = async () => {
    const response = await getData("/allbrands");

    if (response?.success) {
      setBrands(await Select2Data(response?.data, "brand_id"));
    }
  };

  const GetAllOccasion = async () => {
    const response = await getData("/allOccasion");

    if (response?.success) {
      setOccasion(await Select2Data(response?.data, "occasion_id"));
    }
  };

  const GetAllMaterial = async () => {
    const response = await getData("/allMaterial");

    if (response?.success) {
      setMaterial(await Select2Data(response?.data, "material_id"));
    }
  };

  const GetAllClothSize = async () => {
    const response = await getData("/allClothSize");

    if (response?.success) {
      setClothSize(await Select2Data(response?.data, "cloth_size_id"));
    }
  };

  const GetAllTaxTypes = async () => {
    const response = await getData("/all-tax-type");

    if (response?.success) {
      setTaxTypes(await Select2Data(response?.data, "tax_type_id"));
    }
  };

  const GetAllTaxPercentages = async (id) => {
    const response = await getData(`/all-tax-percentage/${id}`);

    if (response?.success) {
      setPercentages(await Select2Data(response?.data, "tax_percentage_id"));
    }
  };

  const GetAllAttributes = async () => {
    const response = await getData(`/all-attribute`);

    if (response?.success) {
      setAttribute(await Select2Data(response?.data, "attribute_id"));
    }
  };

  const GetAllSubAttributes = async (id) => {
    const response = await getData(`/all-sub-attribute/${id}`);

    if (response?.success) {
      setSubAttribute(await Select2Data(response?.data, "sub_attribute_id"));
    }
  };

  // console.log(getValues('images[0]'), 'llll');

  useEffect(() => {
    GetAllCategory();
    GetAllBrands();
    GetAllTaxTypes();
    GetAllAttributes();
    GetAllMaterial();
    GetAllOccasion();
    GetAllClothSize();
  }, []);

  const deleteImage = async (index) => {
    const updatedImagesFile = [...imagesFile];
    updatedImagesFile.splice(index, 1);
    setValue("images", updatedImagesFile);
  };

  const [quantityError, setQuantityError] = useState("");

  
  const {
    fields: projectFields,
    append: appendProject,
    remove: removeProject,
  } = useFieldArray({
    control,
    name: "quantity",
  });

 

  const handleAddQuantity = (variantIndex) => {
    // Get the current variant data
    const currentVariant = variantFields[variantIndex];

    // Create a copy of the current quantities, or start a new array if it doesn't exist
    const updatedQuantities = currentVariant.quantity
      ? [...currentVariant.quantity]
      : [];

    // Push a new empty quantity object into the array
    updatedQuantities.push({ min: "", max: "", unit: "" });

    // Update only the quantity for the specific variant
    updateVariant(variantIndex, {
      ...currentVariant, // Preserve all other fields in the variant
      quantity: updatedQuantities,
    });
  };
  const handleRemoveQuantity = (variantIndex, quantityIndex) => {
    // Get the current variant data
    const currentVariant = variantFields[variantIndex];

    // Create a copy of the current quantities
    const updatedQuantities = [...currentVariant.quantity];

    // Remove the quantity at the specified index
    updatedQuantities.splice(quantityIndex, 1);

    // Update only the quantity for the specific variant
    updateVariant(variantIndex, {
      ...currentVariant, // Preserve all other fields in the variant
      quantity: updatedQuantities,
    });
  };

  const Select3Data = async (data, name, other = false) => {
    const result = data.map((item) => ({
      value: item?.id,
      label: (
        <div>
          <img
            src={`${IMG_URL}${item?.image ?? ''}`}
            alt={item?.name ?? 'image'}
            style={{ width: '20px', height: '20px', marginRight: '8px' }}
          />
          {item?.name ?? ''}
        </div>
      ),
      name: name,
    }));

    if (other) {
      result.push({ value: "0", label: "Other", name: name });
    }

    return result;
  };

  const GetAllCountry = async () => {
    const response = await getData("/allcountry");

    if (response?.success) {
      setCountries(await Select3Data(response?.data, "country_id"));
    }
  };

  useEffect(() => {
    GetAllCountry();
    // setCountries(Select2Data(Country))
  }, []);
  return (
    <>
      <div className="prodcuttttt">
        <Offcanvas
          show={props.show}
          style={{ width: "80%" }}
          placement={"end"}
          onHide={props.handleClose}
        >
          <Offcanvas.Header closeButton>
            {/* <Offcanvas.Title>Add Employee</Offcanvas.Title> */}
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Card className="shadow-lg p-3 mb-5 bg-white rounded">
              <Card.Body>
                <Card.Title>Add Product</Card.Title>
                <hr />
                <Container>
                  <Form
                    // onSubmit={() => handleSubmit(onSubmit)}
                    role="form"
                  // className="stateclass"
                  >
                    <Row>
                      <Col md={3}>
                        <div className="main-form-section my-3">
                          <Row className="row justify-content-center mb-2">
                            <Form.Label>Country</Form.Label>
                            <Controller
                              name="country_id" // name of the field
                              {...register("country_id", {
                                required: "Select Country",
                              })}
                              control={control}
                              render={({ field }) => (
                                <Select
                                  styles={{
                                    control: (baseStyles) => ({
                                      ...baseStyles,
                                      borderColor: errors.country_id
                                        ? "red"
                                        : baseStyles,
                                    }),
                                  }}
                                  {...field}
                                  options={countries}
                                />
                              )}
                            />
                            {errors.country_id && (
                              <span className="text-danger">
                                {errors.country_id.message}
                              </span>
                            )}
                          </Row>
                        </div>
                      </Col>
                      <Col md={3}>
                        <div className="main-form-section my-3">
                          <Row className="row justify-content-center mb-2">
                            <Form.Label>Category</Form.Label>
                            <Controller
                              name="category_id" // name of the field
                              {...register("category_id", {
                                required: "Select Category",
                              })}
                              control={control}
                              render={({ field }) => (
                                <Select
                                  styles={{
                                    control: (baseStyles) => ({
                                      ...baseStyles,
                                      borderColor: errors.category_id
                                        ? "red"
                                        : baseStyles,
                                    }),
                                  }}
                                  {...field}
                                  options={categories}
                                  onChange={(selectedOption) => {
                                    field.onChange(selectedOption.value); // Update Controller's value
                                    GetAllSubCategory(selectedOption.value);
                                    setValue("category_id", selectedOption);
                                    setValue("sub_category_id", "");
                                    setValue("child_sub_category_id", "");
                                  }}
                                />
                              )}
                            />
                            {errors.category_id && (
                              <span className="text-danger">
                                {errors.category_id.message}
                              </span>
                            )}
                          </Row>
                        </div>
                      </Col>

                      <Col md={3}>
                        <div className="main-form-section my-3">
                          <Row className="row justify-content-center mb-2">
                            <Form.Label>Sub Category</Form.Label>
                            <Controller
                              name="sub_category_id" // name of the field
                              {...register("sub_category_id", {
                                required: "Select Sub Category",
                              })}
                              control={control}
                              render={({ field }) => (
                                <Select
                                  styles={{
                                    control: (baseStyles) => ({
                                      ...baseStyles,
                                      borderColor: errors.sub_category_id
                                        ? "red"
                                        : baseStyles,
                                    }),
                                  }}
                                  {...field}
                                  options={subCategories}
                                  onChange={(selectedOption) => {
                                    field.onChange(selectedOption.value); // Update Controller's value
                                    GetAllChildSubCategory(
                                      selectedOption.value
                                    );
                                    setValue("sub_category_id", selectedOption);
                                    setValue("child_sub_category_id", "");
                                  }}
                                />
                              )}
                            />
                            {errors.sub_category_id && (
                              <span className="text-danger">
                                {errors.sub_category_id.message}
                              </span>
                            )}
                          </Row>
                        </div>
                      </Col>



                      <Col md={3}>
                        <div className="main-form-section my-3">
                          <Row className="justify-content-center">
                            <Form.Label>Name</Form.Label>
                            <Form.Group>
                              <InputGroup>
                                <Form.Control
                                  type="text"
                                  name="name"
                                  placeholder="Name .."
                                  className={classNames("", {
                                    "is-invalid": errors?.name,
                                  })}
                                  {...register("name", {
                                    required: "Name is required",
                                  })}
                                />
                              </InputGroup>
                              {errors.name && (
                                <span className="text-danger">
                                  {errors.name.message}
                                </span>
                              )}
                            </Form.Group>
                          </Row>
                        </div>
                      </Col>

                      <Col md={3}>
                        <div className="main-form-section my-3">
                          <Row className="justify-content-center">
                            <Form.Label>Gifting Short Description</Form.Label>
                            <Form.Group>
                              <InputGroup>
                                <Form.Control
                                  type="text"
                                  name="short_description"
                                  placeholder="Short Description"
                                  maxLength={40}
                                  // className={classNames("", {
                                  //   "is-invalid": errors?.short_description,
                                  // })}
                                  {...register("short_description", {
                                    required: "Short Description is required",
                                  })}
                                />
                              </InputGroup>
                              {errors.short_description && (
                                <span className="text-danger">
                                  {errors.short_description.message}
                                </span>
                              )}
                            </Form.Group>
                          </Row>
                        </div>
                      </Col>
                      <Col md={3}>
                        <div className="main-form-section my-3">
                          <Row className="justify-content-center">
                            <Form.Label>Gift Wrap Price</Form.Label>
                            <Form.Group>
                              <InputGroup>
                                <Form.Control
                                  min="0"
                                  type="number"
                                  name="gift_price"
                                  placeholder="Gift Price"
                                  maxLength={40}
                                  // className={classNames("", {
                                  //   "is-invalid": errors?.gift_price,
                                  // })}
                                  {...register("gift_price", {
                                    required: "Gift Price is required",
                                  })}
                                  onKeyDown={(e) => {
                                    if (e.key === '-' || e.key === 'e' || e.key === '+') {
                                      e.preventDefault();
                                    }
                                  }}
                                />
                              </InputGroup>
                              {errors.gift_price && (
                                <span className="text-danger">
                                  {errors.gift_price.message}
                                </span>
                              )}
                            </Form.Group>
                          </Row>
                        </div>
                      </Col>

                      <Col md={3}>
                        <div className="main-form-section my-3">
                          <Form.Label>image</Form.Label>

                          <Form.Group>
                            <Form.Control
                              // className={classNames("", {
                              //   "is-invalid": errors?.image1,
                              // })}
                              type="file"
                              {...register("image1", {
                                required: "images is required",
                              })}
                              accept="image/*"
                            />
                          </Form.Group>
                          {errors.image1 && (
                            <span className="text-danger">
                              {errors.image1.message}
                            </span>
                          )}
                        </div>
                      </Col>

                      <Col md={3}>
                        <div className="main-form-section my-3">
                          <Form.Label>image</Form.Label>
                          {imageFile && imageFile?.length > 0 && (
                            <div className="image-preview-container">
                              <img
                                // src={URL.createObjectURL(getValues("image")[0])}
                                src={URL?.createObjectURL(imageFile[0])}
                                alt="Preview"
                                className="image-preview"
                                style={{ width: "100px", height: "100px" }}
                              />
                            </div>
                          )}
                        </div>
                      </Col>
                      <Col md={3}>
                        {/* <Row className="row justify-content-center mb-2"> */}
                        <Form.Check
                          type="checkbox"
                          label="Customize Status"
                          checked={isCustomizeEnabled}
                          onChange={(e) =>
                            setIsCustomizeEnabled(e.target.checked)
                          }
                        />
                        {isCustomizeEnabled && (
                          <div className="main-form-section my-3">
                            <Row className="justify-content-center">
                              <Form.Label>customize price</Form.Label>
                              <Form.Group>
                                <InputGroup>
                                  <Form.Control
                                    type="text"
                                    name="customize_price"
                                    placeholder="customize price"
                                    maxLength={40}
                                    // className={classNames("", {
                                    //   "is-invalid": errors?.customize_price,
                                    // })}
                                    {...register("customize_price", {
                                      required: "customize price is required",
                                    })}
                                  />
                                </InputGroup>
                                {errors.customize_price && (
                                  <span className="text-danger">
                                    {errors.customize_price.message}
                                  </span>
                                )}
                              </Form.Group>
                            </Row>
                          </div>
                        )}
                        {/* </Row> */}
                      </Col>


                      <div className="main-form-section mt-5"></div>
                      <Card.Title>Product Details</Card.Title>
                      <hr />

                      <Col md={3}>
                        <div className="main-form-section my-3">
                          <Row className="justify-content-center">
                            <Form.Label>manufacturer</Form.Label>
                            <Form.Group>
                              <InputGroup>
                                <Form.Control
                                  type="text"
                                  name="manufacturer"
                                  placeholder="manufacturer"
                                  // className={classNames("", {
                                  //   "is-invalid": errors?.manufacturer,
                                  // })}
                                  {...register("manufacturer", {
                                    required: "manufacturer is required",
                                  })}
                                />
                              </InputGroup>
                              {errors.manufacturer && (
                                <span className="text-danger">
                                  {errors.manufacturer.message}
                                </span>
                              )}
                            </Form.Group>
                          </Row>
                        </div>
                        <div className="main-form-section my-3">
                          <Row className="justify-content-center">
                            <Form.Label>vender</Form.Label>
                            <Form.Group>
                              <InputGroup>
                                <Form.Control
                                  type="text"
                                  name="vender"
                                  placeholder="vender"
                                  // className={classNames("", {
                                  //   "is-invalid": errors?.vender,
                                  // })}
                                  {...register("vender", {
                                    required: "vender is required",
                                  })}
                                />
                              </InputGroup>
                              {errors.vender && (
                                <span className="text-danger">
                                  {errors.vender.message}
                                </span>
                              )}
                            </Form.Group>
                          </Row>
                        </div>
                      </Col>

                      <Col md={3}>
                        <div className="main-form-section my-3">
                          <Row className="row justify-content-center mb-2">
                            <Form.Label>Brand</Form.Label>
                            <Controller
                              name="brand_id" // name of the field
                              {...register("brand_id", {
                                required: "Select Brand",
                              })}
                              control={control}
                              render={({ field }) => (
                                <Select
                                  styles={{
                                    control: (baseStyles) => ({
                                      ...baseStyles,
                                      borderColor: errors.brand_id
                                        ? "red"
                                        : baseStyles,
                                    }),
                                  }}
                                  {...field}
                                  options={brands}
                                />
                              )}
                            />
                            {errors.brand_id && (
                              <span className="text-danger">
                                {errors.brand_id.message}
                              </span>
                            )}
                          </Row>
                        </div>
                      </Col>
                      <Col md={3}>
                        <div className="main-form-section my-3">
                          <Row className="row justify-content-center mb-2">
                            <Form.Label>Occasion</Form.Label>
                            <Controller
                              name="occasion_id" // name of the field
                              {...register("occasion_id", {
                                // required: "Select Occasion",
                              })}
                              control={control}
                              render={({ field }) => (
                                <Select
                                  isMulti
                                  styles={{
                                    control: (baseStyles) => ({
                                      ...baseStyles,
                                      borderColor: errors.occasion_id
                                        ? "red"
                                        : baseStyles,
                                    }),
                                  }}
                                  {...field}
                                  options={occasion}
                                />
                              )}
                            />
                            {errors.occasion_id && (
                              <span className="text-danger">
                                {errors.occasion_id.message}
                              </span>
                            )}
                          </Row>
                        </div>
                      </Col>
                      <Col md={3}>
                        <div className="main-form-section my-3">
                          <Row className="row justify-content-center mb-2">
                            <Form.Label>Material</Form.Label>
                            <Controller
                              name="material_id" // name of the field
                              {...register("material_id", {
                                // required: "Select Material",
                              })}
                              control={control}
                              render={({ field }) => (
                                <Select
                                  isMulti
                                  styles={{
                                    control: (baseStyles) => ({
                                      ...baseStyles,
                                      borderColor: errors.material_id
                                        ? "red"
                                        : baseStyles,
                                    }),
                                  }}
                                  {...field}
                                  options={material}
                                />
                              )}
                            />
                            {errors.material_id && (
                              <span className="text-danger">
                                {errors.material_id.message}
                              </span>
                            )}
                          </Row>
                        </div>
                      </Col>

                      <Col md={3}>
                        <div className="main-form-section my-3">
                          <Row className="row justify-content-center mb-2">
                            <Form.Label>Tax Type</Form.Label>
                            <Controller
                              name="tax_type_id" // name of the field
                              {...register("tax_type_id", {
                                required: "Select Tax Type",
                              })}
                              control={control}
                              render={({ field }) => (
                                <Select
                                  styles={{
                                    control: (baseStyles) => ({
                                      ...baseStyles,
                                      borderColor: errors.tax_type_id
                                        ? "red"
                                        : baseStyles,
                                    }),
                                  }}
                                  {...field}
                                  options={taxTypes}
                                  onChange={(selectedOption) => {
                                    field.onChange(selectedOption.value); // Update Controller's value
                                    GetAllTaxPercentages(selectedOption.value);
                                    setValue("tax_type_id", selectedOption);
                                  }}
                                />
                              )}
                            />
                            {errors.tax_type_id && (
                              <span className="text-danger">
                                {errors.tax_type_id.message}
                              </span>
                            )}
                          </Row>
                        </div>
                      </Col>

                      <Col md={3}>
                        <div className="main-form-section my-3">
                          <Row className="row justify-content-center mb-2">
                            <Form.Label>Tax Percentage</Form.Label>
                            <Controller
                              name="tax_percentage_id" // name of the field
                              {...register("tax_percentage_id", {
                                required: "Select Tax Percentage",
                              })}
                              control={control}
                              render={({ field }) => (
                                <Select
                                  styles={{
                                    control: (baseStyles) => ({
                                      ...baseStyles,
                                      borderColor: errors.tax_percentage_id
                                        ? "red"
                                        : baseStyles,
                                    }),
                                  }}
                                  {...field}
                                  options={taxPercentages}
                                />
                              )}
                            />
                            {errors.tax_percentage_id && (
                              <span className="text-danger">
                                {errors.tax_percentage_id.message}
                              </span>
                            )}
                          </Row>
                        </div>
                      </Col>

                      <Col lg={6}>
                        <div className="main-form-section my-3">
                          <Row className="justify-content-start">
                            {" "}
                            {/* Align to left */}
                            {/* Description */}
                            <Col sm={12}>
                              <Form.Label className="text-center">
                                PRODUCT DESCRIPTION
                              </Form.Label>
                              <Form.Group>
                                <Controller
                                  name="about" // Provide the field name
                                  control={control} // Pass the control object from useForm()
                                  rules={{ required: "about is required" }} // Validation rules
                                  render={({ field }) => (
                                    <JoditEditor
                                      value={field.value}
                                      onChange={(newContent) =>
                                        field.onChange(newContent)
                                      }
                                      onBlur={field.onBlur}
                                      className={classNames("", {
                                        "is-invalid": !!errors.about,
                                      })}
                                      placeholder="about"
                                    />
                                  )}
                                />
                              </Form.Group>
                              {errors.about && (
                                <span className="text-danger">
                                  {errors.about.message}
                                </span>
                              )}
                            </Col>
                          </Row>
                        </div>
                      </Col>

                      <Col lg={6}>
                        <div className="main-form-section my-3">
                          <Row className="justify-content-start">
                            {" "}
                            {/* Align to left */}
                            {/* Description */}
                            <Col sm={12}>
                              <Form.Label className="text-center">
                                PRODUCT CARE
                              </Form.Label>
                              <Form.Group>
                                <Controller
                                  name="benifits" // Provide the field name
                                  control={control} // Pass the control object from useForm()
                                  rules={{ required: "benifits is required" }} // Validation rules
                                  render={({ field }) => (
                                    <JoditEditor
                                      value={field.value}
                                      onChange={(newContent) =>
                                        field.onChange(newContent)
                                      }
                                      onBlur={field.onBlur}
                                      className={classNames("", {
                                        "is-invalid": !!errors.benifits,
                                      })}
                                      placeholder="benifits"
                                    />
                                  )}
                                />
                              </Form.Group>
                              {errors.benifits && (
                                <span className="text-danger">
                                  {errors.benifits.message}
                                </span>
                              )}
                            </Col>
                          </Row>
                        </div>
                      </Col>

                      <Col lg={6}>
                        <div className="main-form-section my-3">
                          <Row className="justify-content-start">
                            {" "}
                            {/* Align to left */}
                            {/* Description */}
                            <Col sm={12}>
                              <Form.Label className="text-center">
                                DELIVERY & RETURNS
                              </Form.Label>
                              <Form.Group>
                                <Controller
                                  name="storage" // Provide the field name
                                  control={control} // Pass the control object from useForm()
                                  rules={{ required: "storage is required" }} // Validation rules
                                  render={({ field }) => (
                                    <JoditEditor
                                      value={field.value}
                                      onChange={(newContent) =>
                                        field.onChange(newContent)
                                      }
                                      onBlur={field.onBlur}
                                      className={classNames("", {
                                        "is-invalid": !!errors.storage,
                                      })}
                                      placeholder="storage"
                                    />
                                  )}
                                />
                              </Form.Group>
                              {errors.storage && (
                                <span className="text-danger">
                                  {errors.storage.message}
                                </span>
                              )}
                            </Col>
                          </Row>
                        </div>
                      </Col>

                      <div className="main-form-section mt-5"></div>
                      <Card.Title>
                        Product variants Details
                        {variantsError && (
                          <div className="text-danger">{variantsError}</div>
                        )}
                      </Card.Title>
                      <hr />

                      {variantFields.map((variant, index) => (
                        <div
                          key={variant.id}
                          className="main-form-section my-3"
                        >
                          <Row>
                            {variant.quantity.map((quantity, quantityIndex) => (
                              <div key={quantity.id} className="main-form-section mt-3">
                                <Row>
                                  <Col md={3}>
                                    <Form.Label>Min Quantity</Form.Label>
                                    <Form.Group>
                                      <InputGroup>
                                        <Form.Control
                                          min="0"
                                          type="number"
                                          name={`variants.${index}.quantity.${quantityIndex}.min`}
                                          placeholder="Min Quantity"
                                          className={classNames("", {
                                            "is-invalid":
                                              errors?.variants?.[index]
                                                ?.quantity?.[quantityIndex]
                                                ?.min,
                                          })}
                                          {...register(
                                            `variants.${index}.quantity.${quantityIndex}.min`,
                                            {
                                              required:
                                                "Min Quantity is required",
                                            }
                                          )}
                                          onKeyDown={(e) => {
                                            if (
                                              e.key === "-" ||
                                              e.key === "e" ||
                                              e.key === "+"
                                            ) {
                                              e.preventDefault();
                                            }
                                          }}
                                        />
                                      </InputGroup>
                                      {errors?.variants?.[index]?.quantity?.[
                                        quantityIndex
                                      ]?.min && (
                                          <span className="text-danger">
                                            {
                                              errors?.variants?.[index]
                                                ?.quantity?.[quantityIndex]?.min
                                                .message
                                            }
                                          </span>
                                        )}
                                    </Form.Group>
                                  </Col>

                                  <Col md={3}>
                                    <Form.Label>Max Quantity</Form.Label>
                                    <Form.Group>
                                      <InputGroup>
                                        <Form.Control
                                          min="0"
                                          type="number"
                                          name={`variants.${index}.quantity.${quantityIndex}.max`}
                                          placeholder="Max Quantity"
                                          className={classNames("", {
                                            "is-invalid":
                                              errors?.variants?.[index]
                                                ?.quantity?.[quantityIndex]
                                                ?.max,
                                          })}
                                          {...register(
                                            `variants.${index}.quantity.${quantityIndex}.max`,
                                            {
                                              required:
                                                "Max Quantity is required",
                                            }
                                          )}
                                          onKeyDown={(e) => {
                                            if (
                                              e.key === "-" ||
                                              e.key === "e" ||
                                              e.key === "+"
                                            ) {
                                              e.preventDefault();
                                            }
                                          }}
                                        />
                                      </InputGroup>
                                      {errors?.variants?.[index]?.quantity?.[
                                        quantityIndex
                                      ]?.max && (
                                          <span className="text-danger">
                                            {
                                              errors?.variants?.[index]
                                                ?.quantity?.[quantityIndex]?.max
                                                .message
                                            }
                                          </span>
                                        )}
                                    </Form.Group>
                                  </Col>

                                  <Col md={3}>
                                    <Form.Label>Price Per Unit</Form.Label>
                                    <Form.Group>
                                      <InputGroup>
                                        <Form.Control
                                          min="0"
                                          type="number"
                                          name={`variants.${index}.quantity.${quantityIndex}.unit`}
                                          placeholder="Price Per Unit"
                                          className={classNames("", {
                                            "is-invalid":
                                              errors?.variants?.[index]
                                                ?.quantity?.[quantityIndex]
                                                ?.unit,
                                          })}
                                          {...register(
                                            `variants.${index}.quantity.${quantityIndex}.unit`,
                                            {
                                              required:
                                                "Price Per Unit is required",
                                            }
                                          )}
                                          onKeyDown={(e) => {
                                            if (
                                              e.key === "-" ||
                                              e.key === "e" ||
                                              e.key === "+"
                                            ) {
                                              e.preventDefault();
                                            }
                                          }}
                                        />
                                      </InputGroup>
                                      {errors?.variants?.[index]?.quantity?.[
                                        quantityIndex
                                      ]?.unit && (
                                          <span className="text-danger">
                                            {
                                              errors?.variants?.[index]
                                                ?.quantity?.[quantityIndex]?.unit
                                                .message
                                            }
                                          </span>
                                        )}
                                    </Form.Group>
                                  </Col>
                                </Row>

                                <button
                                  className="add-varient mt-3"
                                  type="button"
                                  onClick={() =>
                                    handleRemoveQuantity(index, quantityIndex)
                                  }
                                >
                                  Remove Quantity
                                </button>
                              </div>
                            ))}
                            <div className="text-center">
                              <button
                                type="button"
                                className="add-varient"
                                onClick={() => handleAddQuantity(index)}
                              >
                                + Add Quantity
                              </button>
                            </div>

                            <Col md={3}>
                              <div className="main-form-section my-3">
                                <Row className="justify-content-center">
                                  <Form.Label>Variant Name</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        name={`variants.${index}.v_name`} // Register color for the variant
                                        placeholder="Variant Name"
                                        className={classNames("", {
                                          "is-invalid":
                                            errors?.variants?.[index]?.v_name,
                                        })}
                                        {...register(
                                          `variants.${index}.v_name`,
                                          {
                                            required:
                                              "Variant name is required",
                                          }
                                        )}
                                      />
                                    </InputGroup>

                                    {errors?.variants?.[index]?.v_name && (
                                      <span className="text-danger">
                                        {errors.variants[index].v_name.message}
                                      </span>
                                    )}
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>

                            <Col md={3}>
                              <div className="main-form-section my-3">
                                <Row className="justify-content-center">
                                  <Form.Label>Stock Unit</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        min="0"
                                        type="number"
                                        name={`variants.${index}.unit`}
                                        placeholder="Stock Unit"
                                        className={classNames("", {
                                          "is-invalid":
                                            errors?.variants?.[index]?.unit, // Updated error handling
                                        })}
                                        {...register(`variants.${index}.unit`, {
                                          required: "Stock Unit is required",
                                        })}
                                        onKeyDown={(e) => {
                                          if (e.key === '-' || e.key === 'e' || e.key === '+') {
                                            e.preventDefault();
                                          }
                                        }}

                                      />
                                    </InputGroup>
                                    {errors?.variants?.[index]?.unit && (
                                      <span className="text-danger">
                                        {
                                          errors?.variants?.[index]?.unit
                                            .message
                                        }
                                      </span>
                                    )}
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>

                            {/* MRP */}
                            <Col md={3}>
                              <div className="main-form-section my-3">
                                <Row className="justify-content-center">
                                  <Form.Label>mrp</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        min="0"
                                        type="number"
                                        name={`variants.${index}.mrp`}
                                        placeholder="mrp"
                                        className={classNames("", {
                                          "is-invalid":
                                            errors?.variants?.[index]?.mrp, // Updated error handling
                                        })}
                                        {...register(`variants.${index}.mrp`, {
                                          required: "MRP is required",
                                        })}
                                        onKeyDown={(e) => {
                                          if (e.key === '-' || e.key === 'e' || e.key === '+') {
                                            e.preventDefault();
                                          }
                                        }}
                                      />
                                    </InputGroup>
                                    {errors?.variants?.[index]?.mrp && (
                                      <span className="text-danger">
                                        {errors.variants[index].mrp.message}
                                      </span>
                                    )}
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>

                            {/* SKU */}
                            <Col md={3}>
                              <div className="main-form-section my-3">
                                <Row className="justify-content-center">
                                  <Form.Label>sku</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        name={`variants.${index}.sku`}
                                        placeholder="sku"
                                        className={classNames("", {
                                          "is-invalid":
                                            errors?.variants?.[index]?.sku, // Updated error handling
                                        })}
                                        {...register(`variants.${index}.sku`, {
                                          required: "sku is required",
                                        })}
                                      />
                                    </InputGroup>
                                    {errors?.variants?.[index]?.sku && (
                                      <span className="text-danger">
                                        {errors.variants[index].sku.message}
                                      </span>
                                    )}
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>

                            {/* Selling Prise */}
                            <Col md={3}>
                              <div className="main-form-section my-3">
                                <Row className="justify-content-center">
                                  <Form.Label>Selling Price</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        min="0"
                                        type="number"
                                        name={`variants.${index}.s_price`}
                                        placeholder="Selling Price"
                                        className={classNames("", {
                                          "is-invalid":
                                            errors?.variants?.[index]?.s_price, // Updated error handling
                                        })}
                                        {...register(
                                          `variants.${index}.s_price`,
                                          {
                                            required:
                                              "Selling Price is required",
                                            validate: (value) => {
                                              if (!value)
                                                return "Selling Price is required";
                                              const minimumOrderValue =
                                                parseFloat(
                                                  getValues(
                                                    `variants.${index}.mrp`
                                                  )
                                                );
                                              if (parseFloat(value) <= 0) {
                                                return "Selling Price must be a positive number";
                                              }

                                              // Check if discount is less than minimum order value
                                              if (
                                                parseFloat(value) >
                                                minimumOrderValue
                                              ) {
                                                return "Selling Price must not be greater than MRP";
                                              }

                                              return true; // Return true for valid input
                                            },
                                          })}
                                        onKeyDown={(e) => {
                                          if (e.key === '-' || e.key === 'e' || e.key === '+') {
                                            e.preventDefault();
                                          }
                                        }}
                                      />
                                    </InputGroup>
                                    {errors?.variants?.[index]?.s_price && (
                                      <span className="text-danger">
                                        {errors.variants[index].s_price.message}
                                      </span>
                                    )}
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>

                            <hr />




                            <Col lg={6}>
                              {/* <Col md={6}> */}
                              <div className="main-form-section my-3">
                                <Form.Label>Images(Image in 1080 * 1080 dimension)</Form.Label>

                                <Form.Group>
                                  <Form.Control
                                    className={classNames("", {
                                      "is-invalid": errors?.image,
                                    })}
                                    type="file"
                                    multiple
                                    {...register(`variants.${index}.image`, {
                                      required: "images is required",
                                    })}
                                    id={`variantImages${index}`}
                                    accept="image/*"
                                  />
                                </Form.Group>
                                {errors.variants?.[index]?.image && (
                                  <span className="text-danger">
                                    {errors.variants?.[index]?.image.message}
                                  </span>
                                )}
                                {/* <Table striped bordered hover>
                                <thead>
                                  <tr>
                                    <th>Preview</th>
                                    <th>Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {imagesFile && imagesFile?.map((image, imageIndex) => (
                                    <tr key={imageIndex}>
                                      <td>
                                        <img
                                          src={URL.createObjectURL(image)}
                                          alt={`Image ${imageIndex + 1}`}
                                          style={{ width: "100px", height: "100px" }}
                                        />
                                      </td>
                                      <td>
                                        <Button
                                          variant="danger"
                                          onClick={() => deleteImage(index, imageIndex)} // Handle image deletion
                                          style={{ width: "100%", padding: "0.375rem 0.75rem" }}
                                        >
                                          <FontAwesomeIcon icon={["fas", "trash"]} />
                                        </Button>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>

                              </Table> */}
                              </div>
                              {/* </Col> */}
                            </Col>
                            <Col md={5}>
                              {/* <Row className="row justify-content-center mb-2"> */}
                              <Form.Check
                                type="checkbox"
                                label="Cloth Size (Not Require)"
                                checked={isClothSizeEnabled}
                                onChange={(e) =>
                                  setIsClothSizeEnabled(e.target.checked)
                                }
                              />

                              {/* <Col md={5}> */}


                              {isClothSizeEnabled && (
                                // <Row className="row justify-content-center mb-2">
                                <>
                                  <Form.Label>Cloth Size</Form.Label>
                                  <Controller
                                    name={`variants.${index}.cloth_size_id`}
                                    control={control}
                                    render={({ field }) => (
                                      <Select
                                        isMulti
                                        styles={{
                                          control: (baseStyles) => ({
                                            ...baseStyles,
                                            borderColor: errors?.variants?.[index]?.cloth_size_id
                                              ? "red"
                                              : baseStyles.borderColor,
                                          }),
                                        }}
                                        {...field}
                                        options={clothsize}
                                      />
                                    )}
                                    rules={{ required: "Select cloth size" }}
                                  />
                                  {errors?.variants?.[index]?.cloth_size_id && (
                                    <span className="text-danger">
                                      {errors?.variants?.[index]?.cloth_size_id.message}
                                    </span>
                                  )}
                                </>
                                // </Row>
                              )}


                              {/* </Col> */}
                              {/* </Row> */}
                            </Col>

                          </Row>
                          {/* <button className="mt-3 add-varient" type="button" onClick={() => remove(index)}>Remove Variant</button> */}
                          <button
                            className="add-varient"
                            type="button"
                            onClick={() => handleRemoveVariant(index)}
                          >
                            Remove Variant
                          </button>
                        </div>
                      ))}
                      <div className="main-form-section my-3"></div>
                      <hr />
                      <div className="text-center">
                        <button
                          type="button"
                          className="add-varient"
                          onClick={() => handleAddVariant()}
                        >
                          + Add Variant
                        </button>
                        {/* <button type="button" className="add-varient" onClick={() => append({})} >+ Add Variant</button> */}
                      </div>
                      <Row className="mt-5 pb-3">
                        <div className="d-flex justify-content-center">
                          <Link>
                            <CancelButton
                              name={"cancel"}
                              handleClose={props.handleClose}
                            />
                          </Link>

                          <Button
                            name={"save"}
                            onClick={handleSubmit(onSubmit)}
                            type="button"
                            className="save-btnnnnnn"
                          >
                            <FontAwesomeIcon
                              icon="fa-solid fa-floppy-disk"
                              className="me-2"
                            />
                            save
                          </Button>
                        </div>
                      </Row>
                    </Row>
                  </Form>
                </Container>
              </Card.Body>
            </Card>
          </Offcanvas.Body>
        </Offcanvas>

        <ModalSave
          message={showModal.message}
          showErrorModal={showModal.code ? true : false}
        />
      </div>
    </>
  );
};

export default AddOffCanvance;
