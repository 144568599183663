import React, { useContext } from "react";
import { useState, useEffect } from "react";
// import "../../Tabels/Tabels.css";
import { Link } from "react-router-dom";
import { Context } from "../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
// *******************toster****************************
import Select from "react-select";
import ModalSave from "../common/ModelSave";
import Validation from "../common/FormValidation";
import { CancelButton, SaveButton } from "../common/Button";

import { Row, Col, Form, InputGroup, Modal } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import classNames from "classnames";
library.add(fas);

const EditOffCanvance = (props) => {
  const { postData, getData, Select2Data } = useContext(Context);

  const id = props.show;
  const [country, setCountry] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [pincode, setPincode] = useState([]);

  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  const GetEditData = async () => {
    const response = await getData(`/employee/employee-details/add`);
    reset(response?.data);
  };

  const GetAllCountries = async () => {
    const response = await getData("/allcountry");

    if (response?.success) {
      setCountry(await Select2Data(response?.data, "country_id"));
    }
  };

  const GetAllStates = async (id) => {
    const response = await getData(`/allstate/${id}`);

    if (response?.success) {
      setStates(await Select2Data(response?.data, "state_id"));
    }
  };

  const GetAllCities = async (id) => {
    const response = await getData(`/allcity/${id}`);

    if (response?.success) {
      setCities(await Select2Data(response?.data, "city_id"));
    }
  };
  const GetAllPincode = async (id) => {
    const response = await getData(`/allpincodes/${id}`);

    if (response?.success) {
      setPincode(await Select2Data(response?.data, "pincode_id"));
    }
  };

  useEffect(() => {
    GetEditData();
    GetAllCountries();
    GetAllStates();
    GetAllCities();
  }, []);

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setError,
    setValue,
    getValues,
  } = useForm();

  const onSubmit = async (data) => {
    console.log(data);
    if (data.password === data.c_password) {
      try {
        const finalData = new FormData();

        finalData.append("country_id", data.country_id?.value);
        finalData.append("state_id", data.state_id?.value);
        finalData.append("city_id", data.city_id?.value);
        finalData.append("pincode_id", data.pincode_id?.value);
        finalData.append("first_name", data.first_name);
        finalData.append("contact_no", data.contact_no);
        finalData.append("email", data.email);
        finalData.append("store_name", data.store_name);
        finalData.append("store_url", data.store_url);
        finalData.append("gst_number", data.gst_number);
        finalData.append("password", data.password);
        const response = await postData(
          `/employee/employee-details/add`,
          finalData
        );
        console.log(response);
        if (response?.success) {
          await setShowModal({
            code: response.code,
            message: response.message,
          });
        } else {
          await setShowModal({
            code: response.code,
            message: response.message,
          });
        }
        setTimeout(() => {
          setShowModal(0);
          props.handleClose();
        }, 1000);
      } catch (error) {
        console.log(error);
      }
    } else {
      setError("password", {
        message: "Passwords must match",
      });
    }
  };

  return (
    <>
      <Modal
        {...props}
        onHide={props.handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Add Pincode
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            onSubmit={() => handleSubmit(onSubmit)}
            role="form"
            // className="stateclass"
          >
            <Row>
              <Col md={6}>
                <div className="main-form-section my-3">
                  <Form.Group>
                    <div className="text-center">
                      <Form.Label>Name</Form.Label>
                    </div>
                    <InputGroup>
                      <Form.Control
                        type="text"
                        name="first_name"
                        placeholder="Brand"
                        className={classNames("", {
                          "is-invalid": errors?.first_name,
                        })}
                        {...register("first_name", {
                          required: "Name is required",
                        })}
                      />
                    </InputGroup>
                    {errors.first_name && (
                      <span className="text-danger">
                        {errors.first_name.message}
                      </span>
                    )}
                  </Form.Group>
                </div>
              </Col>
              <Col md={6}>
                <div className="main-form-section my-3">
                  <Form.Group>
                    <div className="text-center">
                      <Form.Label>Contact</Form.Label>
                    </div>
                    <InputGroup>
                      <Form.Control
                        type="text"
                        name="contact_no"
                        placeholder="Brand"
                        className={classNames("", {
                          "is-invalid": errors?.contact_no,
                        })}
                        {...register("contact_no", {
                          required: "contact_no is required",
                        })}
                      />
                    </InputGroup>
                    {errors.contact_no && (
                      <span className="text-danger">
                        {errors.contact_no.message}
                      </span>
                    )}
                  </Form.Group>
                </div>
              </Col>
              <Col md={6}>
                <div className="main-form-section my-3">
                  <Form.Group>
                    <div className="text-center">
                      <Form.Label>Email</Form.Label>
                    </div>
                    <InputGroup>
                      <Form.Control
                        type="text"
                        name="email"
                        placeholder="email"
                        className={classNames("", {
                          "is-invalid": errors?.email,
                        })}
                        {...register("email", {
                          required: "email is required",
                        })}
                      />
                    </InputGroup>
                    {errors.email && (
                      <span className="text-danger">
                        {errors.email.message}
                      </span>
                    )}
                  </Form.Group>
                </div>
              </Col>
              <Col md={6}>
                <div className="main-form-section my-3">
                  <Form.Group>
                    <div className="text-center">
                      <Form.Label>Password</Form.Label>
                    </div>
                    <InputGroup>
                      <Form.Control
                        type="password"
                        name="password"
                        placeholder="Create a Password"
                        {...register("password", {
                          required: "Password is required",
                        })}
                        className={classNames("", {
                          "is-invalid": errors?.password,
                          "is-valid": getValues("password"),
                        })}
                      />
                    </InputGroup>
                    {errors.password && (
                      <span className="text-danger">
                        {errors.password.message}
                      </span>
                    )}
                  </Form.Group>
                </div>
              </Col>
              <Col md={6}>
                <div className="main-form-section my-3">
                  <Form.Group>
                    <div className="text-center">
                      <Form.Label>Conform Password</Form.Label>
                    </div>
                    <InputGroup>
                      <Form.Control
                        type="password"
                        name="c_password"
                        placeholder="Confirm Password"
                        {...register("c_password", {
                          required: "Confirmation password is required",
                        })}
                        className={classNames("", {
                          "is-invalid": errors?.c_password,
                          "is-valid": getValues("c_password"),
                        })}
                      />
                    </InputGroup>
                    {errors.c_password && (
                      <span className="text-danger">
                        {errors.c_password.message}
                      </span>
                    )}
                  </Form.Group>
                </div>
              </Col>
              <Col md={6}>
                <div className="main-form-section my-3">
                  <Form.Group>
                    <div className="text-center">
                      <Form.Label>Store Name</Form.Label>
                    </div>
                    <InputGroup>
                      <Form.Control
                        type="text"
                        name="store_name"
                        placeholder="store_name"
                        className={classNames("", {
                          "is-invalid": errors?.store_name,
                        })}
                        {...register("store_name", {
                          required: "store_name is required",
                        })}
                      />
                    </InputGroup>
                    {errors.store_name && (
                      <span className="text-danger">
                        {errors.store_name.message}
                      </span>
                    )}
                  </Form.Group>
                </div>
              </Col>
              <Col md={6}>
                <div className="main-form-section my-3">
                  <Form.Group>
                    <div className="text-center">
                      <Form.Label>Store URL</Form.Label>
                    </div>
                    <InputGroup>
                      <Form.Control
                        type="text"
                        name="store_url"
                        placeholder="store_url"
                        className={classNames("", {
                          "is-invalid": errors?.store_url,
                        })}
                        {...register("store_url", {
                          required: "store_url is required",
                        })}
                      />
                    </InputGroup>
                    {errors.store_url && (
                      <span className="text-danger">
                        {errors.store_url.message}
                      </span>
                    )}
                  </Form.Group>
                </div>
              </Col>
              <Col md={6}>
                <div className="main-form-section my-3">
                  <Form.Group>
                    <div className="text-center">
                      <Form.Label>GST No</Form.Label>
                    </div>
                    <InputGroup>
                      <Form.Control
                        type="text"
                        name="gst_number"
                        placeholder="gst_number"
                        className={classNames("", {
                          "is-invalid": errors?.gst_no,
                        })}
                        {...register("gst_number", {
                          required: "gst_number is required",
                        })}
                      />
                    </InputGroup>
                    {errors.gst_number && (
                      <span className="text-danger">
                        {errors.gst_number.message}
                      </span>
                    )}
                  </Form.Group>
                </div>
              </Col>
              <Col md={6}>
                <div className="main-form-section my-3">
                  <Form.Group>
                    <div className="text-center">
                      <Form.Label>Street Address</Form.Label>
                    </div>
                    <InputGroup>
                      <Form.Control
                        type="text"
                        name="street_address"
                        placeholder="street_address"
                        className={classNames("", {
                          "is-invalid": errors?.street_address,
                        })}
                        {...register("street_address", {
                          required: "street_address is required",
                        })}
                      />
                    </InputGroup>
                    {errors.street_address && (
                      <span className="text-danger">
                        {errors.street_address.message}
                      </span>
                    )}
                  </Form.Group>
                </div>
              </Col>
              <Col md={6}>
                <div className="main-form-section my-3">
                  <Row className="row justify-content-center mb-2">
                    <Form.Label>Country</Form.Label>

                    <Controller
                      name="country_id" // name of the field
                      {...register("country_id", {
                        required: "Select Country",
                      })}
                      control={control}
                      render={({ field }) => (
                        <Select
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              borderColor: errors.country_id
                                ? "red"
                                : baseStyles,
                            }),
                          }}
                          {...field}
                          options={country}
                          onChange={(selectedOption) => {
                            field.onChange(selectedOption.value); // Update Controller's value
                            GetAllStates(selectedOption.value);
                            setValue("country_id", selectedOption);
                            setValue("state_id", null);
                            setValue("city_id", null);
                          }}
                        />
                      )}
                    />

                    {errors.country_id && (
                      <span className="text-danger">
                        {errors.country_id.message}
                      </span>
                    )}
                  </Row>
                </div>
              </Col>

              <Col md={6}>
                <div className="main-form-section my-3">
                  <Row className="row justify-content-center mb-2">
                    <Form.Label>State</Form.Label>

                    <Controller
                      name="state_id" // name of the field
                      {...register("state_id", {
                        required: "Select State",
                      })}
                      control={control}
                      render={({ field }) => (
                        <Select
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              borderColor: errors.state_id ? "red" : baseStyles,
                            }),
                          }}
                          {...field}
                          options={states}
                          onChange={(selectedOption) => {
                            field.onChange(selectedOption.value); // Update Controller's value
                            GetAllCities(selectedOption.value);
                            setValue("state_id", selectedOption);
                            setValue("city_id", null);
                          }}
                        />
                      )}
                    />

                    {errors.state_id && (
                      <span className="text-danger">
                        {errors.state_id.message}
                      </span>
                    )}
                  </Row>
                </div>
              </Col>
              <Col md={6}>
                <div className="main-form-section my-3">
                  <Row className="row justify-content-center mb-2">
                    <Form.Label>City</Form.Label>

                    <Controller
                      name="city_id" // name of the field
                      {...register("city_id", {
                        required: "Select State",
                      })}
                      control={control}
                      render={({ field }) => (
                        <Select
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              borderColor: errors.state_id ? "red" : baseStyles,
                            }),
                          }}
                          {...field}
                          options={cities}
                          onChange={(selectedOption) => {
                            field.onChange(selectedOption.value); // Update Controller's value
                            GetAllPincode(selectedOption.value);
                            setValue("city_id", selectedOption);
                            setValue("pincode_id", null);
                          }}
                        />
                      )}
                    />

                    {errors.state_id && (
                      <span className="text-danger">
                        {errors.state_id.message}
                      </span>
                    )}
                  </Row>
                </div>
              </Col>

              <Col md={6}>
                <div className="main-form-section my-3">
                  <Row className="row justify-content-center mb-2">
                    <Form.Label>Pincode</Form.Label>

                    <Controller
                      name="pincode_id" // name of the field
                      {...register("pincode_id", {
                        required: "Select Pincode",
                      })}
                      control={control}
                      render={({ field }) => (
                        <Select
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              borderColor: errors.city_id ? "red" : baseStyles,
                            }),
                          }}
                          {...field}
                          options={pincode}
                        />
                      )}
                    />

                    {errors.pincode_id && (
                      <span className="text-danger">
                        {errors.pincode_id.message}
                      </span>
                    )}
                  </Row>
                </div>
              </Col>

              <Row className="mt-5 pb-3">
                <div className="d-flex justify-content-center">
                  <Link>
                    <CancelButton
                      name={"cancel"}
                      handleClose={props.handleClose}
                    />
                  </Link>

                  <SaveButton
                    name={"save"}
                    handleSubmit={handleSubmit(onSubmit)}
                  />
                </div>
              </Row>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>

      <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
    </>
  );
};

export default EditOffCanvance;
